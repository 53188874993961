
export default [
    {
        path: "/",
        name: "default",
        meta: {
            title: "Dashboard",
            authRequired: true,
            key: "dashboard"
        },
        component: () => import("../views/dashboard/index.vue"),

    },
    {
        path: "/statistiques",
        name: "statistiques",
        meta: {
            title: "Statistiques",
            authRequired: true,
            key: "statistiques"
        },
        children: [
            {
                path: "identifications",
                name: "statistiqueIdentification",
                meta: {
                    title: "Evenements",
                    authRequired: true,
                    key: "liste-event"
                },
                component: () => import("../views/statistiques/identification.vue"),

            },
            {
                path: "transactions",
                name: "statistiqueTransactions",
                meta: {
                    title: "Transactions",
                    authRequired: true,
                    key: "liste-event"
                },
                component: () => import("../views/statistiques/transaction.vue"),

            },
        ]
    },
    {
        path: "/events",
        name: "events",
        meta: {
            title: "Evenements",
            authRequired: true,
            key: "events"
        },
        children: [
            {
                path: "",
                name: "listEvents",
                meta: {
                    title: "Evenements",
                    authRequired: true,
                    key: "liste-event"
                },
                component: () => import("../views/evenements/index.vue"),

            },
            {
                path: "create",
                name: "createEvents",
                meta: {
                    title: "Evenements",
                    authRequired: true,
                    key: "liste-event"
                },
                component: () => import("../views/evenements/create.vue"),

            },
            {
                path: ":id/edit",
                name: "editEvents",
                meta: {
                    title: "Evenements",
                    authRequired: true,
                    key: "edit-event"
                },
                component: () => import("../views/evenements/edit.vue"),

            },
        ]

    },
    {
        path: "/redevables",
        name: "redevables",
        meta: {
            title: "Usagers",
            authRequired: true,
            key: "redevables"
        },
        children: [
            {
                path: "",
                name: "listeRedevables",
                meta: {
                    title: "Usagers",
                    authRequired: true,
                    key: "dashboard"
                },
                component: () => import("../views/redevables/liste.vue"),
            },
            {
                path: "create",
                name: "addRedevables",
                meta: {
                    title: "Usagers",
                    authRequired: true,
                    key: "dashboard"
                },
                component: () => import("../views/redevables/create.vue"),
            },
            {
                path: "print",
                name: "printRedevables",
                meta: {
                    title: "Usagers",
                    authRequired: true,
                    key: "dashboard"
                },
                component: () => import("../views/redevables/print.vue"),
            },
            {
                path: "print-en-regle",
                name: "printEnregle",
                meta: {
                    title: "Usagers",
                    authRequired: true,
                    key: "dashboard"
                },
                component: () => import("../views/redevables/printEnRegle.vue"),
            },
            {
                path: "print-en-impaye",
                name: "printImpaye",
                meta: {
                    title: "Usagers",
                    authRequired: true,
                    key: "dashboard"
                },
                component: () => import("../views/redevables/printImpaye.vue"),
            },
            {
                path: ":id/fiche",
                name: "ficheRedevables",
                meta: {
                    title: "Usagers",
                    authRequired: true,
                    key: "dashboard"
                },
                component: () => import("../views/redevables/fiche.vue"),
            },
            {
                path: ":id/edit",
                name: "editRedevables",
                meta: {
                    title: "Usagers",
                    authRequired: true,
                    key: "edit-usager"
                },
                component: () => import("../views/redevables/edit.vue"),
            },
        ]

    },
    {
        path: "/transactions",
        name: "transactions",
        meta: {
            title: "Transactions",
            authRequired: true,
            key: "dashboard"
        },
        component: () => import("../views/transactions/liste.vue"),

    },
    {
        path: "/transactions/print",
        name: "printTransactions",
        meta: {
            title: "Transactions",
            authRequired: true,
            key: "dashboard"
        },
        component: () => import("../views/transactions/print.vue"),

    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/auth/login.vue"),
        meta: {
            title: "Login",
        },
    },
    {
        path: "/configurations",
        name: "configurations",
        meta: {
            title: "Configurations",
            authRequired: true,
            key: "configurations"
        },
        children:[
            {
                path: "redevances",
                name: "configRedevances",
                meta: {
                    title: "Redevances",
                    authRequired: true,
                    key: "config-redevances"
                },
                children:[
                    {
                        path: "",
                        name: "configListeRedevances",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-list-redevances"
                        },
                        component: () => import("../views/configurations/redevances/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateRedevances",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-redevances"
                        },
                        component: () => import("../views/configurations/redevances/create.vue"),
                    },
                    {
                        path: ":id/applications",
                        name: "configRedevanceApplications",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-list-redevances"
                        },
                        children:[
                            {
                                path: "",
                                name: "configListRedevanceApplications",
                                meta: {
                                    title: "Redevances",
                                    authRequired: true,
                                    key: "config-list-redevances"
                                },
                                component: () => import("../views/configurations/redevances/applications/index.vue"),
                            },
                            {
                                path: ":appid/edit",
                                name: "configEditRedevanceApplications",
                                meta: {
                                    title: "Redevances",
                                    authRequired: true,
                                    key: "config-list-redevances"
                                },
                                component: () => import("../views/configurations/redevances/applications/edit.vue"),
                            },
                            {
                                path: "create",
                                name: "configCreateRedevanceApplications",
                                meta: {
                                    title: "Redevances",
                                    authRequired: true,
                                    key: "config-list-redevances"
                                },
                                component: () => import("../views/configurations/redevances/applications/create.vue"),
                            },
                        ],
                    },
                ]
            },
            {
                path: "secteurs",
                name: "configSecteurs",
                meta: {
                    title: "Secteurs",
                    authRequired: true,
                    key: "config-secteurs"
                },
                children:[
                    {
                        path: "",
                        name: "configListeSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-list-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/edit.vue"),
                    },
                    {
                        path: ":id/add",
                        name: "configAddSecteurs",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-add-secteurs"
                        },
                        component: () => import("../views/configurations/secteurs/add.vue"),
                    },
                ]
            },
            {
                path: "categorie-activite",
                name: "configCategorieActivites",
                meta: {
                    title: "Catégorie activités",
                    authRequired: true,
                    key: "config-categorie-activite"
                },
                children:[
                    {
                        path: "",
                        name: "configListeCategorieActivite",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-list-categorie-activite"
                        },
                        component: () => import("../views/configurations/categorie_activites/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateCategorieActivite",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-create-categorie-activite"
                        },
                        component: () => import("../views/configurations/categorie_activites/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditCategorieActivite",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-categorie-activite"
                        },
                        component: () => import("../views/configurations/categorie_activites/edit.vue"),
                    },
                ]
            },
            {
                path: "entites",
                name: "configEntites",
                meta: {
                    title: "Bureau Regionnal",
                    authRequired: true,
                    key: "config-entite"
                },
                children:[
                    {
                        path: "",
                        name: "configListeEntite",
                        meta: {
                            title: "Bureau Regionnal",
                            authRequired: true,
                            key: "config-list-entite"
                        },
                        component: () => import("../views/configurations/entites/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateEntite",
                        meta: {
                            title: "Bureau Regionnal",
                            authRequired: true,
                            key: "config-create-entite"
                        },
                        component: () => import("../views/configurations/entites/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditEntite",
                        meta: {
                            title: "Bureau Regionnal",
                            authRequired: true,
                            key: "config-edit-entite"
                        },
                        component: () => import("../views/configurations/entites/edit.vue"),
                    },
                ]
            },
            {
                path: "activite",
                name: "configActivites",
                meta: {
                    title: "Activités",
                    authRequired: true,
                    key: "config-categorie-activite"
                },
                children:[
                    {
                        path: "",
                        name: "configListeActivite",
                        meta: {
                            title: "Activité",
                            authRequired: true,
                            key: "config-list-categorie-activite"
                        },
                        component: () => import("../views/configurations/activite/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateActivite",
                        meta: {
                            title: "Activité",
                            authRequired: true,
                            key: "config-create-categorie-activite"
                        },
                        component: () => import("../views/configurations/activite/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditActivite",
                        meta: {
                            title: "Redevances",
                            authRequired: true,
                            key: "config-create-activite"
                        },
                        component: () => import("../views/configurations/activite/edit.vue"),
                    },
                ]
            },
            {
                path: "roles",
                name: "configRoles",
                meta: {
                    title: "Roles",
                    authRequired: true,
                    key: "config-roles"
                },
                children:[
                    {
                        path: "",
                        name: "configListeRoles",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-list-roles"
                        },
                        component: () => import("../views/configurations/roles/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateRoles",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-create-roles"
                        },
                        component: () => import("../views/configurations/roles/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditRoles",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-create-roles"
                        },
                        component: () => import("../views/configurations/roles/edit.vue"),
                    },
                ]
            },
            {
                path: "users",
                name: "configUsers",
                meta: {
                    title: "Users",
                    authRequired: true,
                    key: "config-users"
                },
                children:[
                    {
                        path: "",
                        name: "configListeUsers",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-list-users"
                        },
                        component: () => import("../views/configurations/users/index.vue"),
                    },
                    {
                        path: "create",
                        name: "configCreateUsers",
                        meta: {
                            title: "Catégorie d'activité",
                            authRequired: true,
                            key: "config-create-roles"
                        },
                        component: () => import("../views/configurations/users/create.vue"),
                    },
                    {
                        path: ":id/edit",
                        name: "configEditUsers",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-edit-users"
                        },
                        component: () => import("../views/configurations/users/edit.vue"),
                    },
                    {
                        path: ":id/affectations",
                        name: "configEditAffectationns",
                        meta: {
                            title: "Affectations",
                            authRequired: true,
                            key: "config-edit-users"
                        },
                        component: () => import("../views/configurations/users/affectations.vue"),
                    },
                ]
            },
            {
                path: "evenements",
                name: "configEvenements",
                meta: {
                    title: "Evenements",
                    authRequired: true,
                    key: "config-evenement"
                },
                children:[
                    {
                        path: "type",
                        name: "configTypeEvenements",
                        meta: {
                            title: "Roles",
                            authRequired: true,
                            key: "config-type-evenements"
                        },
                        children:[
                            {
                                path: "",
                                name: "configListeTypeEvenement",
                                meta: {
                                    title: "Roles",
                                    authRequired: true,
                                    key: "config-list-type-evenements"
                                },
                                component: () => import("../views/configurations/evenements/type/index.vue"),
                            },
                            {
                                path: "create",
                                name: "configCreateTypeEvenement",
                                meta: {
                                    title: "Roles",
                                    authRequired: true,
                                    key: "config-create-type-evenements"
                                },
                                component: () => import("../views/configurations/evenements/type/create.vue"),
                            },
                            {
                                path: ":id/edit",
                                name: "configEditTypeEvenement",
                                meta: {
                                    title: "Type d'evenements",
                                    authRequired: true,
                                    key: "config-edit-type-evenements"
                                },
                                component: () => import("../views/configurations/evenements/type/edit.vue"),
                            },
                        ]
                    },
                    {
                        path: "lieux",
                        name: "configLieuxEvenements",
                        meta: {
                            title: "Lieux d'evenements",
                            authRequired: true,
                            key: "config-lieu-evenements"
                        },
                        children:[
                            {
                                path: "",
                                name: "configListeLieuEvenement",
                                meta: {
                                    title: "Roles",
                                    authRequired: true,
                                    key: "config-list-lieu-evenements"
                                },
                                component: () => import("../views/configurations/evenements/lieu/index.vue"),
                            },
                            {
                                path: "create",
                                name: "configCreateLieuEvenement",
                                meta: {
                                    title: "Lieux d'evenement",
                                    authRequired: true,
                                    key: "config-create-lieu-evenements"
                                },
                                component: () => import("../views/configurations/evenements/lieu/create.vue"),
                            },
                            {
                                path: ":id/edit",
                                name: "configEditLieuEvenement",
                                meta: {
                                    title: "Type d'evenements",
                                    authRequired: true,
                                    key: "config-edit-lieu-evenements"
                                },
                                component: () => import("../views/configurations/evenements/lieu/edit.vue"),
                            },
                        ]
                    },
                ]
            }
        ]

    },

];