import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3';
import 'vue-loading-overlay/dist/css/index.css';
import VueApexCharts from "vue3-apexcharts";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/app.scss"
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-multiselect/dist/vue-multiselect.css"
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import 'vue3-tour/dist/vue3-tour.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import 'vue-rate/dist/vue-rate.css'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import 'v-calendar/dist/style.css';
import print from 'vue3-print-nb'
import OpenLayersMap from 'vue3-openlayers'
import Breadcrumbs from './layout/breadCrumbs.vue';
import VueFeather from "vue-feather";
import VueNumber from "vue-number-animation";
import Lightbox from 'vue-easy-lightbox'
import VueSweetalert2 from 'vue-sweetalert2';
import Toaster from "@meforma/vue-toaster";
import Multiselect from 'vue-multiselect'
import feather from 'feather-icons'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import Notifications from '@kyvg/vue3-notification'
import Vue3Tour from 'vue3-tour'
import AosVue from "aos-vue";
import {VueMasonryPlugin} from 'vue-masonry';
import vueChartist from "vue-chartist"
import VueCountdown from '@chenfengyuan/vue-countdown';
import { quillEditor } from "vue3-quill";
import Datepicker from '@vuepic/vue-datepicker';
import SimpleTypeahead from 'vue3-simple-typeahead';
import rate from 'vue-rate'
import VCalendar from 'v-calendar';
import Select2 from 'vue3-select2-component'

import { createI18n } from 'vue-i18n'
import English from "./locales/en.json"
import Español from "./locales/es.json"
import Deutsch from "./locales/de.json"
import Français from "./locales/fr.json"
import Português from "./locales/pt.json"
import 简体中文 from "./locales/cn.json"
import لعربية from "./locales/ae.json"
import German from "./locales/ge.json"
import Russian from "./locales/ru.json"
import Arabic from "./locales/ar.json"
import VueGoogleMaps from '@fawmi/vue-google-maps';
const i18n = createI18n({ legacy: false, // you must specify 'legacy: false' option
  locale: 'ja',
  messages: {
   English: English,
    Español: Español,
    Deutsch: Deutsch,
    Français: Français,
   Português: Português,
    简体中文: 简体中文,
    لعربية: لعربية,
    German: German,
    Russian: Russian,
    Arabic: Arabic
    }
  })

createApp(App)
.use(store)
.use(router)
.use(Notifications)
.use(Lightbox)
.use(VueSweetalert2)
.use(BootstrapVue3)
 .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBTtTLgJj1xVkmmEA8EX37YNL7VcIJbkK0',
        },
    })
.use(Toaster)
.use(feather)
    .use(VueApexCharts)

.use(AosVue)
.use(VCalendar, {})
.use(rate)
.use(Vue3Tour)
.use(i18n)
.use(VueNumber)
.use(OpenLayersMap)
.use(vueChartist)
.use(PerfectScrollbar)
.use(quillEditor)
.use(SimpleTypeahead)
.use(VueMasonryPlugin)
    .use(print)

.component('multiselect', Multiselect)
.component(VueFeather.name, VueFeather)
.component('Breadcrumbs', Breadcrumbs)
.component(VueCountdown.name, VueCountdown)
.component('Datepicker', Datepicker)
    .component('select2', Select2)

.mount('#app')
